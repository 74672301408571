@import "./common/neru/neru.scss";
@import "~bootstrap/scss/bootstrap-utilities";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/forms";
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #000000;
  font-family: "Manrope", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
